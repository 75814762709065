<template>
  <vca-column>
    <vca-field :label="$t('organisation.header')">
      <vca-row class="default-gap">
        <div class="long">
          <h3>{{ $t("organisation.name.label") }}</h3>
          <vca-input
            v-model.trim="value.name"
            ref="name"
            :rules="$v.value.name"
            :placeholder="$t('organisation.name.placeholder')"
            :errorMsg="$t('organisation.name.errorMsg')"
          />
        </div>
        <div class="short">
          <h3>{{ $t("organisation.abbreviation.label") }}</h3>
          <vca-input
            v-model.trim="value.abbreviation"
            ref="abbreviation"
            :rules="$v.value.abbreviation"
            :placeholder="$t('organisation.abbreviation.placeholder')"
            :errorMsg="$t('organisation.abbreviation.errorMsg')"
          />
        </div>
      </vca-row>
      <h3>{{ $t("organisation.email.label") }}</h3>
      <vca-input
        v-model.trim="value.email"
        ref="email"
        :rules="$v.value.email"
        :placeholder="$t('organisation.email.placeholder')"
        :errorMsg="$t('organisation.email.errorMsg')"
      />
      <OrganisationDefaultASP v-model="value" ref="internal_asp" />
    </vca-field>
    <button class="vca-button" @click="add()">
      {{ $t("button.save") }}
    </button>
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
import OrganisationDefaultASP from "./OrganisationDefaultASP.vue";
export default {
  name: "OrganisationForm",
  components: { OrganisationDefaultASP },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      validation: "organisations/validations",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    add() {
      if (this.$v.$invalid) {
        this.$refs.name.validate();
        this.$refs.email.validate();
        this.$refs.abbreviation.validate();
      } else {
        this.$emit("submit");
      }
    },
  },
};
</script>
